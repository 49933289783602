.container {
  width: 100%;
  min-height: 100vh;

  .top-section {
    background: linear-gradient(88deg, #469fe7 0%, #cf28fa 100%);
    width: 100%;
    height: 50vh;
    max-height: 500px;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 64px;

    .welcome-text {
      margin-top: 5%;
      font-family: "QuickSand Bold";
      font-size: 38px;
      color: #fff;
    }

    .search-container {
      width: 100%;
      max-width: 700px;
      height: 64px;
      background: #fff;
      border-radius: 360px;
      padding: 10px 20px;
      display: flex;

      ::placeholder {
        color: #d3d3d3;
      }

      .search-input {
        border: none;
        height: 100%;
        width: 100%;
        outline: none;
        font-size: 18px;
        font-family: "QuickSand Bold";
      }

      .search-button {
        border-radius: 35px;
        background: #a026ff;
        width: 140px;
        border: none;
        outline: none;
        color: #fff;
        letter-spacing: 5px;
        text-transform: uppercase;
        font-family: "QuickSand Bold";
        font-size: 14px;
      }
    }
  }
}
