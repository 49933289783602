@font-face {
  font-family: "QuickSand Bold";
  src: url("./assets/fonts/Quicksand-Bold.ttf");
}
@font-face {
  font-family: "QuickSand Medium";
  src: url("./assets/fonts/Quicksand-Medium.ttf");
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
